<template><div>2222</div></template>

<script>
import { toBMPPage } from '@/utils/util';
import storage from 'store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
const _token = storage.get(ACCESS_TOKEN);
import queryString from 'query-string';

export default {
  name: 'priceSheetTemplateList',

  created() {
    const search = queryString.stringify({
      token: _token,
    });

    toBMPPage(`/CA_STUDIO/price-sheet-template-list?${search}`, '_blank');
    this.$router.back();
  },
};
</script>
